const API_BASE_URL = "https://prod.gtikit.com/gtikitapplayer";

export async function sendParamsToApi(params) {
  // Convert the params object to a query string
  const queryString = new URLSearchParams(params).toString();

  // Append the query string to the endpoint
  const url = `${API_BASE_URL}/provider/validatebusiness?${queryString}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    console.log('Response Status:', response.status); // Log status code
    console.log('Response OK:', response.ok); // Log OK status
    console.log('Response Headers:', response.headers); // Log headers

    if (response.ok) {
      // Try parsing JSON, handle cases where response is not JSON
      try {
        const data = await response.json();
        console.log('Response Data:', data); // Log data for debugging
        return data;
      } catch (jsonError) {
        console.error('Failed to parse JSON response:', jsonError);
        throw new Error('API returned a non-JSON response');
      }
    } else {
      console.error(`Unexpected API status: ${response.status}`);
      throw new Error(`API responded with status ${response.status}`);
    }
  } catch (error) {
    console.error('Error in fetch:', error.message);
    throw error;
  }
}
