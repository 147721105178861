import React, { useEffect, useState } from 'react';
import '../App.css'; // Import the CSS file for the landing page styling
import { parseParams } from '../utils/parseParams';
import { sendParamsToApi } from '../api/ApiService';

const App = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // To manage the loading state
  const [businessData, setBusinessData] = useState(null); // Store business data

  useEffect(() => {
    // Set the document title dynamically
    document.title = "GTikit Go - Welcome";

    const params = parseParams(window.location.search);
    console.log('Parsed Params:', params); // Log the parsed parameters

    // Check if params are present
    if (params.l || params.p || params.id) {
      sendParamsToApi(params)
        .then((data) => {
          if (data.Result) {
            setBusinessData(data.Result); // Store data.Result in state
          } else {
            setError('Invalid response from API');
          }
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      // No parameters are provided, so we stop loading and show the landing page
      setLoading(false);
    }
  }, []);

  // Display error if there's one
  if (error) {
    return <div>Error: {error}</div>;
  }

  // Show a loading message while waiting for API request
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render the business data if available
  if (businessData) {
    return (
      <div className="page">
        <div className="container">
          <img src={businessData.BannerUrl} alt="GTikit Go Banner" className="banner" />
          <div className="content">
            <div className="title">{businessData.Title}</div>
            <div className="description">{businessData.Description}</div>
            <div className="deals">
              {businessData.Deals.map((deal, index) => (
                <div key={index} className="deal-item">
                  {deal.Name}: {deal.Description}
                </div>
              ))}
            </div>
            <a href={businessData.BuyNowUrl} className="cta-button">Order Now</a>
          </div>
        </div>
      </div>
    );
  }

  // Render the default landing page if no parameters are provided
  return (
    <div className="page">
      <div className="container">
        {/* Logo section */}
        <div className="logo-container">
          <img
            src="https://gtikit-cdn-assets.s3.amazonaws.com/prod/2024/10/GT_1_20241023134531_GTikit_Logo(1024x512px).png"
            alt="GTikit Go Logo"
            className="logo"
          />
        </div>

        {/* Main content */}
        <div className="content">
          <div className="tagline">Experience Events, Deals, and More!</div>
          <p className="description">
            Welcome to GTikit Go, your one-stop solution for finding exciting events and exclusive deals tailored just for you.
            Discover, explore, and engage in a whole new way.
          </p>
          <a href="#explore" className="cta-button">Explore Now</a>
        </div>

        {/* Footer */}
        <footer>
          © 2024 GTikit Go. All rights reserved.
        </footer>
      </div>
    </div>
  );
};

export default App;
