export function parseParams(search) {
  const params = new URLSearchParams(search);
  const result = {};

  // Log the entire search string
  console.log('Search string:', search);

  // Iterate over each parameter and add it to the result object
  params.forEach((value, key) => {
    console.log(`Parameter: ${key}, Value: ${value}`);
    result[key] = value;
  });

  // Log the final result object
  console.log('Parsed Parameters Object:', result);

  return result;
}
